import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {priorityColorDefinitionBackground} from "../../helpers/priorityColorDefinition"
import {usePagination} from "../../hooks/usePagination"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {NavLink, useHistory, useLocation, useParams} from "react-router-dom"
import {filterToString} from "../../helpers/filterToString"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {Helmet} from "react-helmet-async"
import {BackButton} from "../../components/BackButton/BackButton"
import {getGroup, setGroup, setGroupFilter} from "../../store/groupsReducer"
import {multipleExist} from "../../helpers/multipleExist"
import {deleteHost} from "../../store/hostsReducer"
import {Button, Modal} from "react-bootstrap"
import {Loading} from "../../components/Loading/Loading"
import {MyBreadcrumb} from "../../components/MyBreadcrumb/MyBreadcrumb"
import {lightGrayColor} from "../../helpers/colorPicker"
import Moment from "moment/moment"
import {DeviceType} from "../../Types/Types"

export const Group = () => {
    const id = useParams<{ id: string }>().id
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const group = useSelector((state: StateType) => state.groupsReducer.group)
    const filter = useSelector((state: StateType) => state.groupsReducer.groupFilter)
    const controller = useMemo(() => new AbortController(), [])
    const [checked, setChecked] = useState<Array<string>>([])
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const {CustomPagination} = usePagination(filter, group?.hosts?.total, (value) => {
        dispatch(setGroup({...group, hosts: {total: group?.hosts?.total, data: null}}))
        dispatch(setGroupFilter(value))
        history.push({search: filterToString(value)})
        setChecked([])
    })
    const timer = useRef<any>()

    useEffect(() => {
        if (id) {
            const filterString = filterFromString(location.search)
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setGroupFilter({...filterString}))
            } else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, id])

    useEffect(() => {
        const controller = new AbortController()

        if (id && location?.search) {
            dispatch(getGroup({id, controller, ...filterFromString(location.search)}))
            timer.current = setInterval(() => dispatch(getGroup({id, controller, noLoading: true, ...filterFromString(location.search)})), 60000)
        }

        return () => {
            controller.abort()
            clearInterval(timer.current)
        }
    }, [dispatch, id, location])

    useEffect(() => {
        return () => {
            dispatch(setGroup(null))
            controller.abort()
        }
    }, [dispatch, controller])

    const allIds = useMemo(() => group?.hosts?.data?.map(i => i.id) || [], [group])

    const checkAll = useCallback(() => {
        if (group && group?.hosts?.data) {
            const limitHosts = group?.hosts?.data.map(i => i.id)
            if (multipleExist(checked, limitHosts)) {
                setChecked([])
            } else {
                setChecked(limitHosts)
            }
        }
    }, [group, checked])

    const handleCheck = useCallback((value: string) => {
        if (checked.includes(value)) setChecked(checked.filter(i => i !== value))
        else setChecked([...checked, value])
    }, [checked])

    const groupsMap = useMemo(() => group?.hosts?.data && [...group.hosts.data].map(i => {
        let priority = 0
        const itemsWithProblem: Array<DeviceType> = []

        i.devices?.data?.forEach(item => {
            if (item.problem) {
                itemsWithProblem.push(item)
                const parsedPriority = parseInt(item.priority, 10)
                if (parsedPriority > priority) {
                    priority = parsedPriority
                }
            }
        })

        return (
            <tr key={i.id} style={{backgroundColor: !i.active ? lightGrayColor : priorityColorDefinitionBackground(priority)}}>
                <td className="align-middle"><input className="table-checkbox"
                                                    onChange={() => handleCheck(i.id)}
                                                    checked={checked.includes(i.id)}
                                                    value={i.id}
                                                    type="checkbox"/>
                </td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/hosts/${i.id}`}>{i.id}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/hosts/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle">{i.address}</td>
                <td className="align-middle">{itemsWithProblem.map((i, index) => {
                    const lastItem = itemsWithProblem.length === index + 1
                    return <span key={i.id}><NavLink className="custom-link-table-item" to={`/devices/${i.id}`}>{i.name}</NavLink>{!lastItem ? ', ' : ''}</span>
                })}</td>
                <td className="align-middle">{i.lat && i.lon ? 'Установлено' : 'Не установлено'}</td>
                <td className="align-middle">{i.payment?.active && i.payment?.dateEnd ? Moment(i.payment.dateEnd).format('DD.MM.YYYY') : ''}</td>
            </tr>
        )
    }), [group?.hosts, checked, handleCheck])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteHost({id: checked, controller}))
            if (payload) {
                dispatch(getGroup({id, controller}))
                setShowModal(false)
                setChecked([])
            }
        } finally {
            setLoading(false)
        }
    }

    const hostsToDeleteForTable = useMemo(() => {
        const items: Array<{ id: string, name: string }> = []

        group?.hosts?.data?.forEach(i => {
            if (checked.includes(i.id)) items.push(i)
        })

        return items.map(i => {
            return (
                <tr key={i.id}>
                    <td className="align-middle">{i.name}</td>
                </tr>
            )
        })
    }, [checked, group])

    if (!group) return <Loading/>

    return (
        <div className="container">
            <MyBreadcrumb />
            <div className="backButton" onClick={() => history.goBack()}><BackButton/></div>
            <Helmet>
                <title>{group?.name}</title>
            </Helmet>
            <h2 className="mb-2">{group?.name}</h2>
            <div style={{fontSize: 16}}>Населенный пункт: {group?.nameCity}</div>
            <NavLink to={{pathname: `/formGroup/${group.id}`}}
                     className="btn btn-primary mt-2"
            >
                Редактировать
            </NavLink>
            <hr className="mt-4 mb-3"/>
            <h2 className="mb-2">Объекты</h2>
            <NavLink to={{pathname: '/formHost', state: {idGroup: group?.id}}}
                     className="btn btn-primary mt-2"
            >
                Добавить объект
            </NavLink>
            <div className="selectBlock">
                <Select className="searchSelect"
                        value={filter.limit}
                        onChange={(item) => history.replace({search: filterToString({...filter, limit: item, offset: 0})})}
                        options={limitOptionsSelect}
                        isSearchable={false}
                />
                <CustomPagination/>
            </div>
            <div className="table-responsive" style={{flex: 1}}>
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th className="align-middle" scope="col"><input className="table-checkbox"
                                                                        checked={checked.length > 0 && multipleExist(checked, allIds)}
                                                                        onChange={checkAll}
                                                                        type="checkbox"/>
                        </th>
                        <th className="align-middle" scope="col">ID</th>
                        <th className="align-middle" scope="col">Название</th>
                        <th className="align-middle" scope="col">Адрес</th>
                        <th className="align-middle" scope="col">Метрики с проблемами</th>
                        <th className="align-middle" scope="col">Местоположение</th>
                        <th className="align-middle text-center" scope="col">Оплачен до</th>
                    </tr>
                    </thead>
                    <tbody>
                    {groupsMap}
                    </tbody>
                </table>
            </div>
            <div style={{display: 'flex'}}>
                <input type="submit"
                       className="btn btn-danger mb-1"
                       style={{marginRight: 10}}
                       value="Удалить"
                       onClick={() => setShowModal(true)}
                       disabled={loading || checked.length === 0}
                />
                <CustomPagination/>
            </div>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Header style={{justifyContent: 'center'}}>
                            <div style={{textAlign: 'center'}}>{`Вы уверены что хотите удалить ${checked.length > 1 ? 'объекты' : 'объект'}?`}</div>
                        </Modal.Header>
                        <Modal.Body style={{textAlign: 'center', maxHeight: '80vh', overflowY: 'auto'}}>
                            <table className="table table-sm mt-3 table-bordered">
                                <thead>
                                <tr>
                                    <th className="align-middle" scope="col">Название</th>
                                </tr>
                                </thead>
                                <tbody>
                                {hostsToDeleteForTable}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
}
