import {instance, RequestParams, Response} from "./API"
import {AjaxAuthType, AjaxDeviceType} from "../Types/Types"
import {filterToString} from "../helpers/filterToString"

export const ajaxAPI = {
    async getAjaxAuthAll(params: RequestParams): Promise<Response<AjaxAuthType>>
    {
        return (await instance.get("ajax", {signal: params?.controller?.signal})).data
    },
    async createAjaxAuth(params: CreateAjaxAuth): Promise<Response<boolean>>
    {
        return (await instance.post("ajax", {...params}, {signal: params?.controller?.signal})).data
    },
    async editAjaxAuth(params: EditAjaxAuth): Promise<Response<boolean>>
    {
        const {id, ...rest} = params
        return (await instance.put(`ajax/${id}`, {...rest}, {signal: params?.controller?.signal})).data
    },
    async deleteAjaxAuth(params: DeleteAjaxAuth): Promise<Response<boolean>>
    {
        const {id} = params
        return (await instance.delete(`ajax/${id}`, {signal: params?.controller?.signal})).data
    },
    async createAjaxHub(params: CreateAjaxHub): Promise<Response<boolean>>
    {
        return (await instance.post("ajax/hub", {...params}, {signal: params?.controller?.signal})).data
    },
    async editAjaxHub(params: EditAjaxHub): Promise<Response<boolean>>
    {
        const {id, ...rest} = params
        return (await instance.put(`ajax/hub/${id}`, {...rest}, {signal: params?.controller?.signal})).data
    },
    async getAjaxDevices(params: GetAjaxItems): Promise<Response<Array<AjaxDeviceType>>>
    {
        const query = filterToString(params)
        return (await instance.get(`ajax/device${query}`, {signal: params?.controller?.signal})).data
    },
    async createAjaxDevice(params: CreateAjaxDevice): Promise<Response<boolean>>
    {
        return (await instance.post("ajax/device", {...params}, {signal: params?.controller?.signal})).data
    },
    async editAjaxDevice(params: EditAjaxDevice): Promise<Response<boolean>>
    {
        const {id, ...rest} = params
        return (await instance.put(`ajax/device/${id}`, {...rest}, {signal: params?.controller?.signal})).data
    },
}

export interface CreateAjaxAuth extends RequestParams
{
    login: string
    password: string
}

export interface EditAjaxAuth extends CreateAjaxAuth
{
    id: number
}

export interface DeleteAjaxAuth extends RequestParams
{
    id: number
}

export interface GetAjaxItems extends RequestParams
{
    idAjaxHub: string
    idAjaxAuth: number
}

export interface CreateAjaxHub extends RequestParams
{
    idHost: string
    name: string
    active: boolean
    priority: string
    idAjaxHub: string
    idAjaxAuth: number
}

export interface EditAjaxHub extends CreateAjaxHub
{
    id: string
}

export interface CreateAjaxDevice extends RequestParams
{
    idHost: string
    name: string
    active: boolean
    priority: string
    idMasterItem: string
    idAjaxHub: string
    idAjaxDevice: string
    ajaxTriggerType: "online" | "battery" | "tamper" | "power"
}

export interface EditAjaxDevice extends CreateAjaxDevice
{
    id: string
}