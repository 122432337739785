import React, {memo, useCallback, useEffect, useMemo, useState} from "react"
import {Button, Form, Modal} from "react-bootstrap"
import {MySelect} from "../../components/MyForms/MySelect"
import {multipleExist} from "../../helpers/multipleExist"
import {AjaxDeviceType, HostType} from "../../Types/Types"
import {ajaxAPI} from "../../api/ajaxAPI"
import {errorHandler, setMessage, setStatus} from "../../store/appReducer"
import {getHost} from "../../store/hostsReducer"

type PropsTypes = {
    id: string
    host: HostType
    controller: AbortController
    loading: boolean
    setLoading: (value: boolean) => void
    setShowModalAjaxItems: React.Dispatch<React.SetStateAction<boolean>>
    dispatch: any
    ajaxHubsOptions: any
    setProgressTotal: React.Dispatch<React.SetStateAction<number>>
    setProgressCurrent: React.Dispatch<React.SetStateAction<number>>
}
export const AddAjaxItems: React.FC<PropsTypes> = memo(({
                                                            id,
                                                            host,
                                                            setShowModalAjaxItems,
                                                            dispatch,
                                                            loading,
                                                            setLoading,
                                                            controller,
                                                            ajaxHubsOptions,
                                                            setProgressTotal,
                                                            setProgressCurrent,
                                                        }) =>
{
    const [ajaxItemsFiltered, setAjaxItemsFiltered] = useState<Array<AjaxDeviceType & { ajaxTriggerType?: Array<string> }>>([])
    const [checkedItemsOnline, setCheckedItemsOnline] = useState<Array<string>>([])
    const [checkedItemsBattery, setCheckedItemsBattery] = useState<Array<string>>([])
    const [checkedItemsTamper, setCheckedItemsTamper] = useState<Array<string>>([])
    const [selectedAjaxHub, setSelectedAjaxHub] = useState<string | number | null>(null)
    const [active, setActive] = useState(true)

    const allItemsOnlineIds = useMemo(() => ajaxItemsFiltered.filter(i => typeof i.online === "boolean" && i.ajaxTriggerType?.includes("online")).map(i => i.id) || [], [ajaxItemsFiltered])
    const allItemsBatteryIds = useMemo(() => ajaxItemsFiltered.filter(i => typeof i.model?.batteryChargeLevelPercentage === "number" && i.ajaxTriggerType?.includes("battery")).map(i => i.id) || [], [ajaxItemsFiltered])
    const allItemsTamperIds = useMemo(() => ajaxItemsFiltered.filter(i => typeof i.model?.tampered === "boolean" && i.ajaxTriggerType?.includes("tamper")).map(i => i.id) || [], [ajaxItemsFiltered])

    useEffect(() =>
    {
        if (ajaxHubsOptions && !selectedAjaxHub)
        {
            setSelectedAjaxHub(ajaxHubsOptions[0]?.value)
        }
    }, [ajaxHubsOptions, selectedAjaxHub])

    useEffect(() =>
    {
        setAjaxItemsFiltered([])
    }, [selectedAjaxHub])

    const onHideAjaxItemModal = useCallback(() =>
    {
        setShowModalAjaxItems(false)
        setAjaxItemsFiltered([])
    }, [setShowModalAjaxItems])

    const checkAllItemsOnline = useCallback(() =>
    {
        if (ajaxItemsFiltered.length > 0)
        {
            const ajaxItemsFilteredIds = ajaxItemsFiltered.filter(i => typeof i.online === "boolean" && i.ajaxTriggerType?.includes("online")).map(i => i.id)
            if (multipleExist(checkedItemsOnline, ajaxItemsFilteredIds))
            {
                setCheckedItemsOnline([])
            }
            else
            {
                setCheckedItemsOnline(ajaxItemsFilteredIds)
            }
        }
    }, [ajaxItemsFiltered, checkedItemsOnline])

    const checkAllItemsBattery = useCallback(() =>
    {
        if (ajaxItemsFiltered.length > 0)
        {
            const ajaxItemsFilteredIds = ajaxItemsFiltered.filter(i => typeof i.model?.batteryChargeLevelPercentage === "number" && i.ajaxTriggerType?.includes("battery")).map(i => i.id)
            if (multipleExist(checkedItemsBattery, ajaxItemsFilteredIds))
            {
                setCheckedItemsBattery([])
            }
            else
            {
                setCheckedItemsBattery(ajaxItemsFilteredIds)
            }
        }
    }, [ajaxItemsFiltered, checkedItemsBattery])

    const checkAllItemsTamper = useCallback(() =>
    {
        if (ajaxItemsFiltered.length > 0)
        {
            const ajaxItemsFilteredIds = ajaxItemsFiltered.filter(i => typeof i.model?.tampered === "boolean" && i.ajaxTriggerType?.includes("tamper")).map(i => i.id)
            if (multipleExist(checkedItemsTamper, ajaxItemsFilteredIds))
            {
                setCheckedItemsTamper([])
            }
            else
            {
                setCheckedItemsTamper(ajaxItemsFilteredIds)
            }
        }
    }, [ajaxItemsFiltered, checkedItemsTamper])

    const handleCheckItemOnline = useCallback((value: string) =>
    {
        if (checkedItemsOnline.includes(value)) setCheckedItemsOnline(checkedItemsOnline.filter(i => i !== value))
        else setCheckedItemsOnline([...checkedItemsOnline, value])
    }, [checkedItemsOnline])

    const handleCheckItemBattery = useCallback((value: string) =>
    {
        if (checkedItemsBattery.includes(value)) setCheckedItemsBattery(checkedItemsBattery.filter(i => i !== value))
        else setCheckedItemsBattery([...checkedItemsBattery, value])
    }, [checkedItemsBattery])

    const handleCheckItemTamper = useCallback((value: string) =>
    {
        if (checkedItemsTamper.includes(value)) setCheckedItemsTamper(checkedItemsTamper.filter(i => i !== value))
        else setCheckedItemsTamper([...checkedItemsTamper, value])
    }, [checkedItemsTamper])

    const getAjaxItems = useCallback(async () =>
    {
        setCheckedItemsOnline([])
        setCheckedItemsBattery([])
        setCheckedItemsTamper([])
        setProgressTotal(0)
        setProgressCurrent(0)

        if (selectedAjaxHub && host)
        {
            const hub = host?.devices?.data?.find(i => i.id === selectedAjaxHub)
            if (hub && hub.ajax?.idAjaxHub && hub.ajax?.idAjaxAuth)
            {
                try
                {
                    setLoading(true)
                    const res = await ajaxAPI.getAjaxDevices({
                        idAjaxHub: hub.ajax?.idAjaxHub,
                        idAjaxAuth: hub.ajax?.idAjaxAuth,
                        controller,
                    })
                    const notExistItems: Array<AjaxDeviceType & { ajaxTriggerType?: Array<string> }> = []
                    if (res.status === 1)
                    {
                        dispatch(setStatus("reject"))
                        dispatch(setMessage({type: "error", message: res.message}))
                        return
                    }
                    else
                    {
                        res?.data?.forEach((a: AjaxDeviceType) =>
                        {
                            if (typeof a.online === "boolean" && !host?.devices?.data?.find(i => i.ajax?.idAjaxDevice === a.id && i.ajax?.ajaxTriggerType === "online"))
                            {
                                if (!notExistItems.find(i => i.id === a.id && i.ajaxTriggerType?.includes("online")))
                                {
                                    const item = notExistItems.find(i => i.id === a.id)
                                    if (item && item.ajaxTriggerType) item.ajaxTriggerType?.push("online")
                                    else notExistItems.push({...a, ajaxTriggerType: ["online"]})
                                }
                            }

                            if (typeof a.model?.batteryChargeLevelPercentage === "number" && !host?.devices?.data?.find(i => i.ajax?.idAjaxDevice === a.id && i.ajax?.ajaxTriggerType === "battery"))
                            {
                                if (!notExistItems.find(i => i.id === a.id && i.ajaxTriggerType?.includes("battery")))
                                {
                                    const item = notExistItems.find(i => i.id === a.id)
                                    if (item && item.ajaxTriggerType) item.ajaxTriggerType?.push("battery")
                                    else notExistItems.push({...a, ajaxTriggerType: ["battery"]})
                                }
                            }

                            if (typeof a.model?.tampered === "boolean" && !host?.devices?.data?.find(i => i.ajax?.idAjaxDevice === a.id && i.ajax?.ajaxTriggerType === "tamper"))
                            {
                                if (!notExistItems.find(i => i.id === a.id && i.ajaxTriggerType?.includes("tamper")))
                                {
                                    const item = notExistItems.find(i => i.id === a.id)
                                    if (item && item.ajaxTriggerType) item.ajaxTriggerType?.push("tamper")
                                    else notExistItems.push({...a, ajaxTriggerType: ["tamper"]})
                                }
                            }
                        })
                        if (notExistItems.length > 0)
                        {
                            setAjaxItemsFiltered(notExistItems)
                            setShowModalAjaxItems(true)
                        }
                        else dispatch(setMessage({type: "info", message: "Все оборудование добавлено"}))
                    }
                } catch (e: any)
                {
                    errorHandler(e, dispatch)
                } finally
                {
                    setLoading(false)
                }
            }
        }
    }, [selectedAjaxHub, controller, dispatch, host, setLoading, setShowModalAjaxItems, setProgressCurrent, setProgressTotal])

    const submitAjaxItems = useCallback(async () =>
    {
        const finallyMethod = () =>
        {
            dispatch(getHost({id, controller}))
            onHideAjaxItemModal()
            setLoading(false)
            setCheckedItemsOnline([])
            setCheckedItemsBattery([])
            setCheckedItemsTamper([])
            setProgressTotal(0)
            setProgressCurrent(0)
        }

        if (selectedAjaxHub && host)
        {
            const hub = host?.devices?.data?.find(i => i.id === selectedAjaxHub)
            if (hub && hub.ajax?.idAjaxHub && hub.ajax?.idAjaxAuth)
            {
                setLoading(true)
                for await (const i of ajaxItemsFiltered)
                {
                    setProgressTotal(checkedItemsOnline.length + checkedItemsBattery.length + checkedItemsTamper.length)
                    setShowModalAjaxItems(false)
                    if (checkedItemsOnline.includes(i.id))
                    {
                        try
                        {
                            const res = await ajaxAPI.createAjaxDevice({
                                controller,
                                idHost: host.id,
                                name: `${hub.name} - ${i.deviceName} (Контроль сети)`,
                                idAjaxHub: hub.ajax?.idAjaxHub,
                                idAjaxDevice: i.id,
                                idMasterItem: hub.id,
                                active: active,
                                priority: "3",
                                ajaxTriggerType: "online",
                            })
                            if (res?.status === 1)
                            {
                                dispatch(setMessage({type: "error", message: res.message}))
                                finallyMethod()
                                break
                            }
                            else dispatch(setMessage({type: "success", message: `Метрика ${i.deviceName} (${i.id}) (Контроль сети) добавлено`}))
                            setProgressCurrent(prev => prev + 1)
                        } catch (e: any)
                        {
                            errorHandler(e, dispatch)
                            finallyMethod()
                            break
                        }
                    }
                    if (checkedItemsBattery.includes(i.id))
                    {
                        if (i.deviceType === "RangeExtender")
                        {
                            try
                            {
                                const res = await ajaxAPI.createAjaxDevice({
                                    controller,
                                    idHost: host.id,
                                    name: `${hub.name} - ${i.deviceName} (Контроль питания)`,
                                    idAjaxHub: hub.ajax?.idAjaxHub,
                                    idAjaxDevice: i.id,
                                    idMasterItem: hub.id,
                                    active: active,
                                    priority: "3",
                                    ajaxTriggerType: "power",
                                })
                                if (res?.status === 1)
                                {
                                    dispatch(setMessage({type: "error", message: res.message}))
                                    finallyMethod()
                                    break
                                }
                                else dispatch(setMessage({type: "success", message: `Метрика ${i.deviceName} (${i.id}) (Контроль питания) добавлено`}))
                            } catch (e: any)
                            {
                                errorHandler(e, dispatch)
                                finallyMethod()
                                break
                            }
                        }

                        try
                        {
                            const res = await ajaxAPI.createAjaxDevice({
                                controller,
                                idHost: host.id,
                                name: `${hub.name} - ${i.deviceName} (Контроль заряда)`,
                                idAjaxHub: hub.ajax?.idAjaxHub,
                                idAjaxDevice: i.id,
                                idMasterItem: hub.id,
                                active: active,
                                priority: "3",
                                ajaxTriggerType: "battery",
                            })
                            if (res?.status === 1)
                            {
                                dispatch(setMessage({type: "error", message: res.message}))
                                finallyMethod()
                                break
                            }
                            else dispatch(setMessage({type: "success", message: `Метрика ${i.deviceName} (${i.id}) (Контроль заряда) добавлено`}))
                            setProgressCurrent(prev => prev + 1)
                        } catch (e: any)
                        {
                            errorHandler(e, dispatch)
                            finallyMethod()
                            break
                        }
                    }
                    if (checkedItemsTamper.includes(i.id))
                    {
                        try
                        {
                            const res = await ajaxAPI.createAjaxDevice({
                                controller,
                                idHost: host.id,
                                name: `${hub.name} - ${i.deviceName} (Контроль корпуса)`,
                                idAjaxHub: hub.ajax?.idAjaxHub,
                                idAjaxDevice: i.id,
                                idMasterItem: hub.id,
                                active: active,
                                priority: "3",
                                ajaxTriggerType: "tamper",
                            })
                            if (res?.status === 1)
                            {
                                dispatch(setMessage({type: "error", message: res.message}))
                                finallyMethod()
                                break
                            }
                            else dispatch(setMessage({type: "success", message: `Метрика ${i.deviceName} (${i.id}) (Контроль корпуса) добавлено`}))
                            setProgressCurrent(prev => prev + 1)
                        } catch (e: any)
                        {
                            errorHandler(e, dispatch)
                            finallyMethod()
                            break
                        }
                    }
                }
                finallyMethod()
            }
        }
    }, [host, controller, dispatch, id, checkedItemsOnline, checkedItemsBattery, checkedItemsTamper, ajaxItemsFiltered,
        selectedAjaxHub, onHideAjaxItemModal, setLoading, setShowModalAjaxItems, setProgressTotal, setProgressCurrent, active])

    const ajaxItemsMap = useMemo(() => ajaxItemsFiltered && [...ajaxItemsFiltered].map(i =>
    {
        return (
            <tr key={i.id}>
                <td className="align-middle">
                    <label style={{width: "100%"}}>
                        <input className="table-checkbox"
                               onChange={() => handleCheckItemOnline(i.id)}
                               checked={checkedItemsOnline.includes(i.id)}
                               value={i.id}
                               type="checkbox"
                               disabled={typeof i.online !== "boolean" || !i.ajaxTriggerType?.includes("online")}
                        />
                    </label>
                </td>
                <td className="align-middle">
                    <label style={{width: "100%"}}>
                        <input className="table-checkbox"
                               onChange={() => handleCheckItemBattery(i.id)}
                               checked={checkedItemsBattery.includes(i.id)}
                               value={i.id}
                               type="checkbox"
                               disabled={typeof i.model?.batteryChargeLevelPercentage !== "number" || !i.ajaxTriggerType?.includes("battery")}
                        />
                    </label>
                </td>
                <td className="align-middle">
                    <label style={{width: "100%"}}>
                        <input className="table-checkbox"
                               onChange={() => handleCheckItemTamper(i.id)}
                               checked={checkedItemsTamper.includes(i.id)}
                               value={i.id}
                               type="checkbox"
                               disabled={typeof i.model?.tampered !== "boolean" || !i.ajaxTriggerType?.includes("tamper")}
                        />
                    </label>
                </td>
                <td className="align-middle">{i.id}</td>
                <td className="align-middle">{i.deviceName}</td>
                <td className="align-middle">{i.deviceType}</td>
            </tr>
        )
    }), [ajaxItemsFiltered, handleCheckItemOnline, handleCheckItemBattery, handleCheckItemTamper, checkedItemsOnline, checkedItemsBattery, checkedItemsTamper])

    return (
        <>
            <Modal show
                   onHide={onHideAjaxItemModal}
                   size={ajaxItemsMap?.length > 0 ? "lg" : "sm"}
            >
                <Modal.Body style={{textAlign: "center"}}>
                    <div style={{width: 250, margin: "0 auto"}}>
                        <MySelect value={selectedAjaxHub}
                                  label="Ajax Systems Hub"
                                  options={ajaxHubsOptions}
                                  onChange={(item) => setSelectedAjaxHub(item)}
                        />
                    </div>
                    <Button variant="outline-secondary"
                            onClick={onHideAjaxItemModal}>
                        Отмена
                    </Button>
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || !selectedAjaxHub}
                            style={{marginLeft: 15}}
                            onClick={getAjaxItems}
                    >
                        {loading ? "Загрузка..." : "Запросить"}
                    </Button>
                    {
                        ajaxItemsMap?.length > 0
                            ? <div>
                                <table className="table table-sm mt-3">
                                    <thead>
                                    <tr>
                                        <th className="align-middle" scope="col" style={{width: 90}}>
                                            <label>
                                                <div style={{marginBottom: 5}}>Контроль сети</div>
                                                <input className="table-checkbox"
                                                       onChange={checkAllItemsOnline}
                                                       checked={checkedItemsOnline.length > 0 && multipleExist(checkedItemsOnline, allItemsOnlineIds)}
                                                       type="checkbox"
                                                />
                                            </label>
                                        </th>
                                        <th className="align-middle" scope="col" style={{width: 90}}>
                                            <label>
                                                <div style={{marginBottom: 5}}>Контроль заряда</div>
                                                <input className="table-checkbox"
                                                       onChange={checkAllItemsBattery}
                                                       checked={checkedItemsBattery.length > 0 && multipleExist(checkedItemsBattery, allItemsBatteryIds)}
                                                       type="checkbox"
                                                />
                                            </label>
                                        </th>
                                        <th className="align-middle" scope="col" style={{width: 90}}>
                                            <label>
                                                <div style={{marginBottom: 5}}>Контроль корпуса</div>
                                                <input className="table-checkbox"
                                                       onChange={checkAllItemsTamper}
                                                       checked={checkedItemsTamper.length > 0 && multipleExist(checkedItemsTamper, allItemsTamperIds)}
                                                       type="checkbox"
                                                />
                                            </label>
                                        </th>
                                        <th className="align-middle" scope="col">ID</th>
                                        <th className="align-middle" scope="col">Название</th>
                                        <th className="align-middle" scope="col">Тип</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {ajaxItemsMap}
                                    </tbody>
                                </table>
                                <div className="checkbox mb-3 mt-3">
                                    <label>
                                        <Form.Check type="checkbox"
                                                    id={"active"}
                                                    checked={active}
                                                    onChange={() => setActive(prev => !prev)}
                                                    label="Активировано"
                                        />
                                    </label>
                                </div>
                            </div>
                            : null
                    }
                </Modal.Body>
                {
                    ajaxItemsMap?.length > 0
                        ? <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={onHideAjaxItemModal}>
                                Отмена
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={submitAjaxItems}
                                    disabled={loading || (checkedItemsOnline?.length === 0 && checkedItemsBattery?.length === 0 && checkedItemsTamper?.length === 0)}
                            >
                                {loading ? "Загрузка..." : "Добавить"}
                            </Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>
        </>
    )
})