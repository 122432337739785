import React, {memo, useEffect, useMemo} from "react"
import {useFormik} from "formik"
import {errorHandler, setMessage} from "../../store/appReducer"
import {getHost} from "../../store/hostsReducer"
import * as yup from "yup"
import {useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {HostType} from "../../Types/Types"
import {Button, Form, Modal} from "react-bootstrap"
import {MyInput} from "../../components/MyForms/MyInput"
import {MySelect} from "../../components/MyForms/MySelect"
import {NavLink} from "react-router-dom"
import {getAjaxAuthAll} from "../../store/ajaxAuthReducer"
import {ajaxAPI} from "../../api/ajaxAPI"
import {Loading} from "../../components/Loading/Loading"

const schema = yup.object().shape({
    name: yup.string().required('Введите название'),
    idAjaxHub: yup.string().required('Введите ID Ajax Hub'),
    idAjaxAuth: yup.number().min(1, 'Выберите аккаунт').required('Выберите аккаунт'),
})

type PropsTypes = {
    id: string
    host: HostType
    controller: AbortController
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setShowModalAddAjaxHub: React.Dispatch<React.SetStateAction<boolean>>
    dispatch: any
}
export const AddAjaxHub: React.FC<PropsTypes> = memo(({
                                                          id,
                                                          host,
                                                          controller,
                                                          loading,
                                                          setLoading,
                                                          dispatch,
                                                          setShowModalAddAjaxHub,
                                                      }) => {
    const ajaxAuthAll = useSelector((state: StateType) => state.ajaxAuthReducer.ajaxAuthAll)

    useEffect(() => {
        dispatch(getAjaxAuthAll({controller}))
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            name: 'Ajax Hub',
            idAjaxHub: '',
            idAjaxAuth: null,
            active: true,
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async () => {
            if (host && values.idAjaxHub && values.idAjaxAuth) {
                try {
                    setLoading(true)
                    const res = await ajaxAPI.createAjaxHub({
                        controller,
                        idHost: host.id,
                        // @ts-ignore
                        idAjaxAuth: values.idAjaxAuth,
                        idAjaxHub: values.idAjaxHub.toUpperCase(),
                        name: values.name,
                        active: values.active,
                        priority: "4",
                    })
                    if (res?.status === 1) dispatch(setMessage({type: 'error', message: res.message}))
                    else {
                        dispatch(getHost({id, controller}))
                        dispatch(setMessage({type: 'success', message: `Хаб добавлен`}))
                        setShowModalAddAjaxHub(false)
                    }
                } catch (e: any) {
                    errorHandler(e, dispatch)
                } finally {
                    setLoading(false)
                }
            }
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue, isValid, resetForm} = formik

    const ajaxAuthOptions = useMemo(() => ajaxAuthAll?.data && [...ajaxAuthAll.data].map(({id, login}) => ({label: login, value: id})), [ajaxAuthAll])

    useEffect(() => {
        if (ajaxAuthOptions && !values?.idAjaxAuth) {
            setFieldValue('idAjaxAuth', ajaxAuthOptions[0]?.value)
        }
    }, [values, ajaxAuthOptions, setFieldValue])

    return (
        <Modal show
               onHide={() => setShowModalAddAjaxHub(false)}
        >
            <Modal.Body style={{textAlign: 'center'}}>
                {
                    ajaxAuthOptions
                        ? <div style={{width: 300, margin: '0 auto'}}>
                            <MyInput value={values.name}
                                     label="Название"
                                     name="name"
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     clear={() => {
                                         setFieldValue('name', '')
                                     }}
                                     isInvalid={touched.name && errors.name}
                            />
                            <MyInput value={values.idAjaxHub}
                                     label="Ajax Hub ID"
                                     name="idAjaxHub"
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     style={{textTransform: 'uppercase'}}
                                     clear={() => {
                                         setFieldValue('idAjaxHub', '')
                                     }}
                                     isInvalid={touched.idAjaxHub && errors.idAjaxHub}
                            />
                            {
                                ajaxAuthOptions.length > 0 ? <MySelect value={values.idAjaxAuth}
                                                                       label="Аккаунт Ajax Systems"
                                                                       options={ajaxAuthOptions}
                                                                       onChange={(item) => setFieldValue('idAjaxAuth', item)}
                                        // @ts-ignore
                                                                       error={touched.idAjaxAuth && errors.idAjaxAuth}
                                    />
                                    : <div className="mb-4">
                                        <NavLink to="/profile">Необходимо добавить аккаунт Ajax Systems</NavLink>
                                    </div>
                            }
                            <div className="checkbox mb-3 mt-3">
                                <label>
                                    <Form.Check type="checkbox"
                                                id="active"
                                                checked={values.active}
                                                onChange={handleChange}
                                                label="Активировано"
                                    />
                                </label>
                            </div>
                        </div>
                        : <Loading/>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                        onClick={() => setShowModalAddAjaxHub(false)}>
                    Отмена
                </Button>
                <Button variant="primary"
                        type="submit"
                        disabled={loading || isSubmitting || !isValid}
                        style={{marginLeft: 15}}
                        onClick={(e: any) => handleSubmit(e)}
                >
                    {loading ? 'Загрузка...' : 'Добавить'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
})
