import {instance, RequestParams, Response} from "./API"
import {ArtaInfoMonitoring, ArtaServer} from "../Types/Types"

export const artaAPI = {
    async getArtaServer(params: GetArtaServer): Promise<Response<ArtaServer>>
    {
        const {id} = params
        return (await instance.get(`arta/server/${id}`, {signal: params?.controller?.signal})).data
    },
    async createArtaServer(params: CreateArtaServer): Promise<Response<boolean>>
    {
        return (await instance.post("arta/server", {...params}, {signal: params?.controller?.signal})).data
    },
    async editArtaServer(params: EditArtaServer): Promise<Response<boolean>>
    {
        const {id, ...rest} = params
        return (await instance.put(`arta/server/${id}`, {...rest}, {signal: params?.controller?.signal})).data
    },
    async getArtaInfoMonitoring(params: GetArtaInfoMonitoring): Promise<Response<ArtaInfoMonitoring>>
    {
        const {idServer} = params
        return (await instance.get(`arta/infoMonitoring/${idServer}`, {signal: params?.controller?.signal})).data
    },
    async createArtaDevice(params: CreateArtaDevice): Promise<Response<boolean>>
    {
        return (await instance.post("arta/device", {...params}, {signal: params?.controller?.signal})).data
    },
    async editArtaDevice(params: EditArtaDevice): Promise<Response<boolean>>
    {
        const {id, ...rest} = params
        return (await instance.put(`arta/device/${id}`, {...rest}, {signal: params?.controller?.signal})).data
    },
}

export interface GetArtaServer extends RequestParams
{
    id: number
}

export interface CreateArtaServer extends RequestParams
{
    idHost: string
    name: string
    port: number
    login: string
    password: string
    active: boolean
    priority: string
}

export interface EditArtaServer extends CreateArtaServer
{
    id: string
}

export interface GetArtaInfoMonitoring extends RequestParams
{
    idServer: number
}

export interface CreateArtaDevice extends RequestParams
{
    name: string
    idHost: string
    control: string
    idMasterItem: string
    active: boolean
    priority: string
    idArtaServer?: number | null
    artaTriggerType: "camera" | "record" | "drive" | "ram" | "temperature"
}

export interface EditArtaDevice extends CreateArtaDevice
{
    id: string
}