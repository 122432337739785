import {instance, RequestParams, Response} from "./API"
import {DeviceType} from "../Types/Types"

export const deviceAPI = {
    async getDevice(params: GetDevice): Promise<Response<DeviceType>> {
        return (await instance.get(`device/${params.id}`, {signal: params?.controller?.signal})).data
    },
    async createDevice(params: CreateDevice): Promise<Response<boolean>> {
        return (await instance.post('device', {...params}, {signal: params?.controller?.signal})).data
    },
    async editDevice(params: EditDevice): Promise<Response<boolean>> {
        return (await instance.put(`device/${params.id}`, {...params}, {signal: params?.controller?.signal})).data
    },
    async deleteDevice(params: DeleteDevice): Promise<Response<boolean>> {
        return (await instance.delete('device', {signal: params?.controller?.signal, data: params.id})).data
    },
    async activateDevice(params: ActivateDevice): Promise<Response<boolean>> {
        return (await instance.get(`device/activate?id=${params.id}&active=${params.active}`, {signal: params?.controller?.signal})).data
    },
}

export interface GetDevice extends RequestParams {
    id: string
}

export interface CreateDevice extends RequestParams {
    name: string
    idHost: string
    active: boolean
    priority: string
    port: string
}

export interface EditDevice extends CreateDevice {
    id: string
}

export interface DeleteDevice extends RequestParams {
    id: Array<string>
}

export interface ActivateDevice extends RequestParams {
    id: string
    active: boolean
}