import React, {memo, useEffect, useMemo, useState} from "react"
import {Button, Form, Modal} from "react-bootstrap"
import {Helmet} from "react-helmet-async"
import {useDispatch} from "react-redux"
import {useFormik} from "formik"
import * as yup from "yup"
import {useHistory, useLocation, useParams} from "react-router-dom"
import {ArtaServer, DeviceType} from "../../Types/Types"
import {createDevice, deleteDevice, editAjaxDevice, editAjaxHub, editArtaDevice, editArtaServer, editDevice, getDeviceNoData} from "../../store/deviceReducer"
import {BackButton} from "../../components/BackButton/BackButton"
import {MyInput} from "../../components/MyForms/MyInput"
import {MySelect} from "../../components/MyForms/MySelect"
import {Loading} from "../../components/Loading/Loading"
import {artaAPI} from "../../api/artaAPI"

type ValuesType = {
    idHost: string
    name: string
    port: string
    priority: string
    active: true
    artaServerPort: number
    artaServerLogin: string
    artaServerPassword: string
    artaCameraPort: string
}

export const FormDevice = memo(() =>
{
    const dispatch = useDispatch()
    const location = useLocation<{ params: DeviceType, idHost: string }>()
    const {id} = useParams<{ id: string }>()
    const params = location?.state?.params
    const idHost = location?.state?.idHost
    const history = useHistory()
    const [device, setDevice] = useState<DeviceType | null>(null)
    const [artaServer, setArtaServer] = useState<ArtaServer | null>(null)
    const [loading, setLoading] = useState(false)
    const controller = useMemo(() => new AbortController(), [])
    const [showModal, setShowModal] = useState(false)

    const schema = useMemo(() =>
    {
        let obj: any = {
            name: yup.string().required("Введите название").max(100, "Максимальная длина 100 символов"),
        }

        if (device?.arta?.idArtaServer)
        {
            obj.artaServerPort = yup.string().matches(
                /^([0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
                "Введите корректный порт",).required("Введите порт")
            obj.artaServerLogin = yup.string().required("Введите логин")
            obj.artaServerPassword = yup.string().required("Введите пароль")
        }
        else if (device?.arta?.artaTriggerType === "camera")
        {
            obj.artaCameraPort = yup.string().matches(
                /^([0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
                "Введите корректный порт",).required("Введите порт")
        }
        else if (!device?.arta && !device?.ajax)
        {
            obj.port = yup.string().required("Введите порт").matches(
                /^([0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
                "Введите корректный порт",
            )
        }

        return yup.object().shape(obj)
    }, [device])

    useEffect(() =>
    {
        (async () =>
        {
            if (id)
            {
                const {payload}: any = await dispatch(getDeviceNoData({id, controller}))
                if (payload) setDevice(payload)
            }
        })()
    }, [dispatch, id, controller])

    useEffect(() =>
    {
        (async () =>
        {
            if (device?.arta?.idArtaServer)
            {
                const res = await artaAPI.getArtaServer({id: device.arta.idArtaServer, controller})
                if (res.data) setArtaServer(res.data)
            }
        })()
    }, [device, controller])

    useEffect(() =>
    {
        return () =>
        {
            controller.abort()
        }
    }, [dispatch, controller])

    const formik = useFormik({
        initialValues: {
            idHost: device?.idHost || params?.idHost || idHost || "",
            name: device?.name || params?.name || "",
            port: device?.port || params?.port || "",
            priority: device?.priority || params?.priority || "3",
            active: device ? device.active : params ? params.active : true,
            artaServerPort: artaServer ? artaServer.port : "",
            artaServerLogin: artaServer ? artaServer.login : "",
            artaServerPassword: "",
            artaCameraPort: device?.arta?.control ? device.arta.control : "",
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) =>
        {
            await onSubmit(values as ValuesType)
        },
    })
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        validateForm,
        isValid,
        setTouched,
        resetForm,
    } = formik

    const onSubmit = async (values: ValuesType, isContinue = false) =>
    {
        await setTouched(values as any)
        await validateForm(values)

        if (isValid)
        {
            const params = {
                id,
                idHost: values.idHost,
                name: values.name,
                port: values.port,
                priority: values.priority,
                active: values.active,
            }

            if (device)
            {
                try
                {
                    setLoading(true)
                    let res: any
                    if (device?.arta?.idArtaServer)
                    {
                        const {payload}: any = await dispatch(editArtaServer({
                            ...params,
                            port: values.artaServerPort,
                            login: values.artaServerLogin,
                            password: values.artaServerPassword,
                            controller,
                        }))
                        res = payload
                    }
                    else if (device?.arta?.idMasterItem)
                    {
                        const {payload}: any = await dispatch(editArtaDevice({
                            ...params,
                            control: device.arta.artaTriggerType === "camera" ? values.artaCameraPort : device?.arta?.control,
                            idMasterItem: device?.arta?.idMasterItem,
                            idArtaServer: device?.arta?.idArtaServer,
                            artaTriggerType: device?.arta?.artaTriggerType,
                            controller,
                        }))
                        res = payload
                    }
                    else if (device?.ajax?.idAjaxDevice && device?.ajax?.idAjaxHub && !device?.ajax?.idAjaxAuth)
                    {
                        const {payload}: any = await dispatch(editAjaxDevice({
                            ...params,
                            idMasterItem: device.idMasterItem,
                            idAjaxHub: device.ajax.idAjaxHub,
                            idAjaxDevice: device.ajax.idAjaxDevice,
                            ajaxTriggerType: device.ajax.ajaxTriggerType,
                            controller,
                        }))
                        res = payload
                    }
                    else if (device?.ajax?.idAjaxDevice && device?.ajax?.idAjaxHub && device?.ajax?.idAjaxAuth)
                    {
                        const {payload}: any = await dispatch(editAjaxHub({
                            ...params,
                            idAjaxHub: device.ajax.idAjaxHub,
                            idAjaxAuth: device.ajax.idAjaxAuth,
                            controller,
                        }))
                        res = payload
                    }
                    else
                    {
                        const {payload}: any = await dispatch(editDevice({...params, controller}))
                        res = payload
                    }
                    if (res === true)
                    {
                        if (isContinue) history.replace("/formDevice", {idHost: values.idHost})
                        else history.goBack()
                    }
                } finally
                {
                    setLoading(false)
                }
            }
            else
            {
                try
                {
                    setLoading(true)
                    const {payload}: any = await dispatch(createDevice({...params, controller}))
                    if (payload)
                    {
                        if (isContinue)
                        {
                            resetForm()
                            history.replace("/formDevice", {idHost: values.idHost})
                        }
                        else history.goBack()
                    }
                } finally
                {
                    setLoading(false)
                }
            }
        }
    }

    const priorityOptions = useMemo(() => [
        {label: "Низкий", value: "1"},
        {label: "Средний", value: "3"},
        {label: "Высокий", value: "4"},
    ], [])

    const deleteHandler = async () =>
    {
        try
        {
            setLoading(true)
            const {payload}: any = await dispatch(deleteDevice({id: [id], controller}))
            if (payload)
            {
                history.goBack()
                history.goBack()
            }
        } finally
        {
            setLoading(false)
        }
    }

    if (id && !device) return <Loading/>

    return (
        <div className="text-center" style={{flex: 1, display: "flex"}}>
            <Helmet>
                <title>{id ? "Изменение метрики" : "Добавление метрики"}</title>
            </Helmet>
            <Form onSubmit={handleSubmit}
                  autoComplete="off"
                  className="formSignin"
            >
                <div className="backButtonForm" onClick={() => history.goBack()}><BackButton/></div>
                <h1 className="mt-6 mb-4">{id ? "Изменение метрики" : "Добавление метрики"}</h1>
                <div className="formInputs">
                    <MyInput value={values.name}
                             label="Название"
                             name="name"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() =>
                             {
                                 setFieldValue("name", "")
                             }}
                             isInvalid={touched.name && errors.name}
                    />
                    {
                        !device?.arta && !device?.ajax
                            ? <MyInput value={values.port}
                                       label="Порт"
                                       name="port"
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       clear={() =>
                                       {
                                           setFieldValue("port", "")
                                       }}
                                       isInvalid={touched.port && errors.port}
                            />
                            : null
                    }
                    {
                        device?.ajax?.idAjaxDevice
                            ? <MyInput value={device.ajax?.idAjaxDevice}
                                       label="ID Ajax Systems"
                                       name="idAjaxDevice"
                                       disabled
                            />
                            : null
                    }
                    {
                        device?.arta?.idMasterItem
                            ? <MyInput value={device.arta.idMasterItem}
                                       label="АРТА Сервер"
                                       name="artaServerControl"
                                       disabled
                            />
                            : null
                    }
                    {
                        device?.arta?.idArtaServer
                            ? <>
                                <MyInput value={values.artaServerPort}
                                         label="Порт"
                                         name="artaServerPort"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         clear={() => setFieldValue("artaServerPort", "")}
                                         isInvalid={touched.artaServerPort && errors.artaServerPort}
                                />
                                <MyInput value={values.artaServerLogin}
                                         autoComplete="new-password"
                                         label="Логин"
                                         name="artaServerLogin"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         clear={() => setFieldValue("artaServerLogin", "")}
                                         isInvalid={touched.artaServerLogin && errors.artaServerLogin}
                                />
                                <MyInput value={values.artaServerPassword}
                                         autoComplete="new-password"
                                         label="Пароль"
                                         name="artaServerPassword"
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         clear={() => setFieldValue("artaServerPassword", "")}
                                         isInvalid={touched.artaServerPassword && errors.artaServerPassword}
                                         isPassword
                                />
                            </>
                            : null
                    }
                    {
                        device?.arta?.artaTriggerType === "camera"
                            ? <MyInput value={values.artaCameraPort}
                                       label="Порт"
                                       name="artaCameraPort"
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       clear={() => setFieldValue("artaCameraPort", "")}
                                       isInvalid={touched.artaCameraPort && errors.artaCameraPort}
                            />
                            : null
                    }
                    <MySelect value={values.priority}
                              label="Приоритет"
                              options={priorityOptions}
                              onChange={(item) => setFieldValue("priority", item)}
                              error={touched.priority && !!errors.priority && errors.priority}
                    />
                    <div className="checkbox mb-3 mt-3">
                        <label>
                            <Form.Check type="checkbox"
                                        id="active"
                                        checked={values.active}
                                        onChange={handleChange}
                                        label="Активировано"
                            />
                        </label>
                    </div>
                </div>
                <div className="form-buttons-block">
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || isSubmitting}
                    >
                        Сохранить
                    </Button>
                    {
                        device?.ajax?.idAjaxDevice ? null : <Button variant="primary"
                                                                    disabled={loading || isSubmitting}
                                                                    onClick={() => onSubmit(values as ValuesType, true)}
                        >
                            Продолжить
                        </Button>
                    }
                    <Button variant="outline-secondary"
                            onClick={() => history.goBack()}
                    >
                        Отмена
                    </Button>
                    {
                        device
                            ? <Button variant="outline-danger"
                                      onClick={() => setShowModal(true)}
                                      disabled={loading || isSubmitting}
                            >
                                Удалить
                            </Button>
                            : null
                    }
                </div>
            </Form>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Body style={{textAlign: "center"}}>
                            <div>Вы уверены что хотите удалить метрику?</div>
                            {device?.ajax?.idAjaxAuth ? <div>При удалении Ajax Hub удалятся все связанные метрики</div> : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading || isSubmitting}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
        </div>
    )
})
