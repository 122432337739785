import React, {memo} from "react"
import {useFormik} from "formik"
import {errorHandler, setMessage} from "../../store/appReducer"
import {getHost} from "../../store/hostsReducer"
import * as yup from "yup"
import {HostType} from "../../Types/Types"
import {Button, Form, Modal} from "react-bootstrap"
import {MyInput} from "../../components/MyForms/MyInput"
import {artaAPI} from "../../api/artaAPI"

export const schema = yup.object().shape({
    name: yup.string().required("Введите название"),
    port: yup.string().matches(
        /^([0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
        "Введите корректный порт",
    ).required("Введите порт"),
    login: yup.string().required("Введите логин"),
    password: yup.string().required("Введите пароль"),
})

type PropsTypes = {
    id: string
    host: HostType
    controller: AbortController
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    setShowModalAddArtaServer: React.Dispatch<React.SetStateAction<boolean>>
    dispatch: any
}
export const AddArtaServer: React.FC<PropsTypes> = memo(({
                                                             id,
                                                             host,
                                                             controller,
                                                             loading,
                                                             setLoading,
                                                             dispatch,
                                                             setShowModalAddArtaServer,
                                                         }) =>
{
    const formik = useFormik({
        initialValues: {
            name: "",
            port: "",
            login: "",
            password: "",
            active: true,
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async () =>
        {
            if (host)
            {
                try
                {
                    setLoading(true)
                    const res = await artaAPI.createArtaServer({
                        controller,
                        idHost: host.id,
                        name: values.name,
                        port: parseInt(values.port, 10),
                        login: values.login,
                        password: values.password,
                        active: values.active,
                        priority: "4",
                    })
                    if (res?.status === 1) dispatch(setMessage({type: "error", message: res.message}))
                    else
                    {
                        dispatch(getHost({id, controller}))
                        dispatch(setMessage({type: "success", message: `Сервер добавлен`}))
                        setShowModalAddArtaServer(false)
                    }
                } catch (e: any)
                {
                    errorHandler(e, dispatch)
                } finally
                {
                    setLoading(false)
                }
            }
        },
    })
    const {values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, setFieldValue, isValid} = formik

    return (
        <Modal show
               onHide={() => setShowModalAddArtaServer(false)}
        >
            <Modal.Body style={{textAlign: "center"}}>
                <div style={{width: 300, margin: "0 auto"}}>
                    <MyInput value={values.name}
                             label="Название"
                             name="name"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue("name", "")}
                             isInvalid={touched.name && errors.name}
                    />

                    <MyInput value={values.port}
                             label="Порт"
                             name="port"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue("port", "")}
                             isInvalid={touched.port && errors.port}
                    />

                    <MyInput value={values.login}
                             autoComplete='new-password'
                             label="Логин"
                             name="login"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue("login", "")}
                             isInvalid={touched.login && errors.login}
                    />

                    <MyInput value={values.password}
                             autoComplete='new-password'
                             label="Пароль"
                             name="password"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             clear={() => setFieldValue("password", "")}
                             isInvalid={touched.password && errors.password}
                             isPassword
                    />

                    <div className="checkbox mb-3 mt-3">
                        <label>
                            <Form.Check type="checkbox"
                                        id="active"
                                        checked={values.active}
                                        onChange={handleChange}
                                        label="Активировано"
                            />
                        </label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                        onClick={() => setShowModalAddArtaServer(false)}>
                    Отмена
                </Button>
                <Button variant="primary"
                        type="submit"
                        disabled={loading || isSubmitting || !isValid}
                        style={{marginLeft: 15}}
                        onClick={(e: any) => handleSubmit(e)}
                >
                    {loading ? "Загрузка..." : "Добавить"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
})
