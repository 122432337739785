import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {StateType} from "../../store/store"
import {priorityColorDefinitionBackground} from "../../helpers/priorityColorDefinition"
import {usePagination} from "../../hooks/usePagination"
import Select from "react-select"
import {limitOptionsSelect} from "../../helpers/tableHelper"
import {NavLink, useHistory, useLocation} from "react-router-dom"
import {filterToString} from "../../helpers/filterToString"
import {filterFromString} from "../../helpers/filterFromString"
import deepEqual from "deep-equal"
import {Helmet} from "react-helmet-async"
import {deleteHost, getHosts, setHosts, setHostsFilter} from "../../store/hostsReducer"
import {DeviceType, GroupType} from "../../Types/Types"
import {multipleExist} from "../../helpers/multipleExist"
import {Button, Modal} from "react-bootstrap"
import {Loading} from "../../components/Loading/Loading"
import {lightGrayColor} from "../../helpers/colorPicker"
import Moment from "moment"
import {getGroupsNoData} from "../../store/groupsReducer"
import {GetAllResponse} from "../../api/API"
import {errorHandler, setMessage} from "../../store/appReducer"
import {hostAPI} from "../../api/hostAPI"
import ProgressBar from "react-bootstrap/ProgressBar"
import {MyInput} from "../../components/MyForms/MyInput"
import {MySelect} from "../../components/MyForms/MySelect"

export const Hosts = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const hosts = useSelector((state: StateType) => state.hostsReducer.hosts)
    const filter = useSelector((state: StateType) => state.hostsReducer.filter)
    const controller = useMemo(() => new AbortController(), [])
    const [checked, setChecked] = useState<Array<string>>([])
    const [loading, setLoading] = useState(false)
    const [progressTotal, setProgressTotal] = useState(0)
    const [progressCurrent, setProgressCurrent] = useState(0)
    const [groups, setGroups] = useState<GetAllResponse<GroupType> | null>(null)
    const [showModal, setShowModal] = useState(false)
    const {CustomPagination} = usePagination(filter, hosts?.total, (value) => {
        dispatch(setHosts({total: hosts?.total, data: null}))
        dispatch(setHostsFilter(value))
        history.push({search: filterToString(value)})
        setChecked([])
    })
    const timer = useRef<any>()

    useEffect(() => {
        (async () => {
            const {payload}: any = await dispatch(getGroupsNoData({enrich: false, controller}))
            if (payload) setGroups(payload)
        })()
    }, [dispatch, controller])

    useEffect(() => {
        if (groups) {
            const filterString = filterFromString(location.search, {groups})
            if (filterString && !deepEqual(filterString, filter)) {
                dispatch(setHostsFilter({...filterString}))
            }
            else if (location.search !== filterToString(filter)) {
                history.replace({search: filterToString(filter)})
            }
        }
    }, [dispatch, filter, history, location, groups])

    useEffect(() => {
        const controller = new AbortController()

        if (location?.search) {
            dispatch(getHosts({controller, ...filterFromString(location.search)}))
            timer.current = setInterval(() => dispatch(getHosts({...filterFromString(location.search), controller, noLoading: true})), 60000)
        }

        return () => {
            clearInterval(timer.current)
            controller.abort()
        }
    }, [dispatch, location])

    const allIds = useMemo(() => hosts?.data?.map(i => i.id) || [], [hosts])

    const checkAll = useCallback(() => {
        if (hosts) {
            const limitHosts = hosts?.data?.map(i => i.id)
            if (multipleExist(checked, limitHosts)) {
                setChecked([])
            }
            else {
                setChecked(limitHosts)
            }
        }
    }, [hosts, checked])

    const handleCheck = useCallback((value: string) => {
        if (checked.includes(value)) setChecked(checked.filter(i => i !== value))
        else setChecked([...checked, value])
    }, [checked])

    const deleteHandler = async () => {
        try {
            setLoading(true)
            const {payload}: any = await dispatch(deleteHost({id: checked, controller}))
            if (payload) {
                dispatch(getHosts({...filterFromString(location?.search)}))
                setShowModal(false)
                setChecked([])
            }
        }
        finally {
            setLoading(false)
        }
    }

    const activateHandler = useCallback(async (active: boolean) => {
        const finallyMethod = () => {
            dispatch(getHosts({controller, ...filterFromString(location.search)}))
            setChecked([])
            setLoading(false)
            setProgressTotal(0)
            setProgressCurrent(0)
        }

        if (checked && hosts) {
            setLoading(true)
            for await (const id of checked) {
                const host = hosts?.data?.find(i => i.id === id)
                if (host) {
                    setProgressTotal(checked.length)
                    try {
                        const res = await hostAPI.activateHost({
                            id: host.id,
                            active: active,
                            controller,
                        })
                        if (res?.status === 1) {
                            dispatch(setMessage({type: "error", message: res.message}))
                            finallyMethod()
                            break
                        }
                        else dispatch(setMessage({type: "success", message: `Объект ${host.name} ${active ? "активирован" : "деактивирован"}`}))
                        setProgressCurrent(prev => prev + 1)
                    }
                    catch (e: any) {
                        errorHandler(e, dispatch)
                        finallyMethod()
                        break
                    }
                }
            }
            finallyMethod()
        }
    }, [checked, hosts, controller, dispatch, location])

    const groupsOptions = useMemo(() => groups?.data?.map(({id, name}) => ({label: name, value: id})), [groups])

    const objectsMap = useMemo(() => hosts?.data && [...hosts.data].map(i => {
        let priority = 0
        const itemsWithProblem: Array<DeviceType> = []

        i.devices?.data?.forEach(item => {
            if (item.problem) {
                itemsWithProblem.push(item)

                const parsedPriority = parseInt(item.priority, 10)

                if (parsedPriority > priority) {
                    priority = parsedPriority
                }
            }
        })

        return (
            <tr key={i.id} style={{backgroundColor: !i.active ? lightGrayColor : priorityColorDefinitionBackground(priority)}}>
                <td className="align-middle">
                    <input className="table-checkbox"
                           onChange={() => handleCheck(i.id)}
                           checked={checked.includes(i.id)}
                           value={i.id}
                           type="checkbox"
                    />
                </td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/hosts/${i.id}`}>{i.id}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/hosts/${i.id}`}>{i.name}</NavLink></td>
                <td className="align-middle"><NavLink className="custom-link-table-item" to={`/groups/${i.idGroup}`}>{i.nameGroup}</NavLink></td>
                <td className="align-middle">{i.address}</td>
                <td className="align-middle">{itemsWithProblem.map((i, index) => {
                    const lastItem = itemsWithProblem.length === index + 1
                    return <span key={i.id}><NavLink className="custom-link-table-item" to={`/devices/${i.id}`}>{i.name}</NavLink>{!lastItem ? ", " : ""}</span>
                })}</td>
                <td className="align-middle">{i.lat && i.lon ? "Установлено" : "Не установлено"}</td>
                <td className="align-middle">{i.payment?.active && i.payment?.dateEnd ? `До ${Moment(i.payment.dateEnd).format("DD.MM.YYYY")}` : ""}</td>
            </tr>
        )
    }), [hosts, handleCheck, checked])

    const hostsToDeleteForTable = useMemo(() => {
        const items: Array<{ id: string, name: string }> = []

        hosts?.data?.forEach(i => {
            if (checked.includes(i.id)) items.push(i)
        })

        return items.map(i => {
            return (
                <tr key={i.id}>
                    <td className="align-middle">{i.name}</td>
                </tr>
            )
        })
    }, [checked, hosts])

    return (
        <div className="table-responsive container" style={{flex: 1}}>
            <Helmet>
                <title>Объекты</title>
            </Helmet>
            <h2 className="mb-2">Объекты</h2>
            <NavLink to="/formHost"
                     className="btn btn-primary mt-2"
            >
                Добавить объект
            </NavLink>
            <div className="selectBlock" style={{justifyContent: "space-between"}}>
                <div className="subSelectBlock">
                    <Select className="searchSelect"
                            value={filter.limit}
                            onChange={(item) => {
                                dispatch(setHosts({total: hosts?.total, data: null}))
                                history.replace({search: filterToString({...filter, limit: item, offset: 0})})
                            }}
                            options={limitOptionsSelect}
                            isSearchable={false}
                    />
                    <CustomPagination/>
                </div>
                <div className="subSelectBlock">
                    <MyInput value={filter.searchName || ""}
                             label="Название"
                             wrapperStyle={{marginBottom: 0}}
                             style={{width: 200}}
                             onChange={(e) => {
                                 dispatch(setHosts({total: hosts?.total, data: null}))
                                 history.replace({search: filterToString({...filter, searchName: e.target.value, offset: 0})})
                             }}
                             clear={() => {
                                 dispatch(setHosts({total: hosts?.total, data: null}))
                                 history.replace({search: filterToString({...filter, searchName: "", offset: 0})})
                             }}
                    />
                    <div style={{minWidth: 200}}>
                        <MySelect value={filter.idGroup}
                                  label={filter.idGroup ? "Группа" : "Все группы"}
                                  wrapperStyle={{marginBottom: 0}}
                                  clearable
                                  options={groupsOptions || []}
                                  onChange={(item) => {
                                      dispatch(setHosts({total: hosts?.total, data: null}))
                                      history.replace({search: filterToString({...filter, idGroup: item, offset: 0})})
                                  }}
                        />
                    </div>
                </div>
            </div>
            <table className="table table-sm">
                <thead>
                <tr>
                    <th className="align-middle" scope="col">
                        <input className="table-checkbox"
                               checked={checked.length > 0 && multipleExist(checked, allIds)}
                               onChange={checkAll}
                               type="checkbox"
                        />
                    </th>
                    <th className="align-middle" scope="col">ID</th>
                    <th className="align-middle" scope="col">Название</th>
                    <th className="align-middle" scope="col">Группа</th>
                    <th className="align-middle" scope="col">Адрес</th>
                    <th className="align-middle" scope="col">Проблемы</th>
                    <th className="align-middle" scope="col">Местоположение</th>
                    <th className="align-middle" scope="col">Тариф</th>
                </tr>
                </thead>
                <tbody>
                {objectsMap || <tr>
                    <td colSpan={8}><Loading/></td>
                </tr>}
                </tbody>
            </table>
            <div style={{display: "flex"}}>
                <input type="submit"
                       className="btn btn-danger mb-1"
                       style={{marginRight: 10}}
                       value="Удалить"
                       onClick={() => setShowModal(true)}
                       disabled={loading || checked.length === 0}
                />
                <input type="submit"
                       className="btn btn-success mb-1"
                       style={{marginRight: 10}}
                       value="Активировать"
                       onClick={() => activateHandler(true)}
                       disabled={loading || checked.length === 0}
                />
                <input type="submit"
                       className="btn btn-secondary mb-1"
                       style={{marginRight: 10}}
                       value="Деактивировать"
                       onClick={() => activateHandler(false)}
                       disabled={loading || checked.length === 0}
                />
                <CustomPagination/>
            </div>
            {
                showModal
                    ? <Modal show
                             onHide={() => setShowModal(false)}
                    >
                        <Modal.Header style={{justifyContent: "center"}}>
                            <div style={{textAlign: "center"}}>{`Вы уверены что хотите удалить ${checked.length > 1 ? "объекты" : "объект"}?`}</div>
                        </Modal.Header>
                        <Modal.Body style={{textAlign: "center", maxHeight: "80vh", overflowY: "auto"}}>
                            <table className="table table-sm mt-3 table-bordered">
                                <thead>
                                <tr>
                                    <th className="align-middle" scope="col">Название</th>
                                </tr>
                                </thead>
                                <tbody>
                                {hostsToDeleteForTable}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={() => setShowModal(false)}>
                                Нет
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={deleteHandler}
                                    disabled={loading}
                            >
                                Да
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            {
                progressTotal > 0
                    ? <Modal show centered>
                        <Modal.Body>
                            <ProgressBar animated
                                         now={progressCurrent}
                                         min={0}
                                         max={progressTotal}
                                         label={<div>{progressCurrent} / {progressTotal}</div>}
                            />
                        </Modal.Body>
                    </Modal>
                    : null
            }
        </div>
    )
}
