import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {DeviceType, SelectOption} from "../Types/Types"
import {limitOptionsSelect} from "../helpers/tableHelper"
import {asyncCreator} from "./appReducer"
import {CreateDevice, DeleteDevice, deviceAPI, EditDevice, GetDevice} from "../api/deviceAPI"
import {ajaxAPI, EditAjaxDevice, EditAjaxHub} from "../api/ajaxAPI"
import {artaAPI, EditArtaDevice, EditArtaServer} from "../api/artaAPI"

export type EventsFilter = {
    limit: SelectOption<number>
    offset: number
}

type InitialStateType = {
    device: DeviceType | null
    filter: EventsFilter
}

const initialState: InitialStateType = {
    device: null,
    filter: {
        limit: limitOptionsSelect[0],
        offset: 0,
    },
}

export const deviceReducer = createSlice({
    name: 'deviceReducer',
    initialState,
    reducers: {
        setDevice(state, {payload}) {
            state.device = payload
        },
        setDeviceFilter(state, {payload}: PayloadAction<any>) {
            state.filter = payload
        },
    },
})

export const {
    setDevice, setDeviceFilter,
} = deviceReducer.actions

export default deviceReducer.reducer

export const getDevice = asyncCreator<GetDevice>('deviceReducer/getDevice', deviceAPI.getDevice, setDevice)
export const createDevice = asyncCreator<CreateDevice>('deviceReducer/createDevice', deviceAPI.createDevice, null, 'Метрика добавлена')
export const editDevice = asyncCreator<EditDevice>('deviceReducer/editDevice', deviceAPI.editDevice, null, 'Изменения сохранены')
export const deleteDevice = asyncCreator<DeleteDevice>('deviceReducer/deleteDevice', deviceAPI.deleteDevice, null, 'Метрика удалена')

export const editAjaxHub = asyncCreator<EditAjaxHub>('deviceReducer/editAjaxHub', ajaxAPI.editAjaxHub, null, 'Изменения сохранены')
export const editAjaxDevice = asyncCreator<EditAjaxDevice>('deviceReducer/editAjaxDevice', ajaxAPI.editAjaxDevice, null, 'Изменения сохранены')

export const editArtaServer = asyncCreator<EditArtaServer>('deviceReducer/editArtaServer', artaAPI.editArtaServer, null, 'Изменения сохранены')
export const editArtaDevice = asyncCreator<EditArtaDevice>('deviceReducer/editArtaDevice', artaAPI.editArtaDevice, null, 'Изменения сохранены')

export const getDeviceNoData = asyncCreator<GetDevice>('deviceReducer/getDeviceNoData', deviceAPI.getDevice)


