import React, {memo, useCallback, useEffect, useMemo, useState} from "react"
import {Button, Form, Modal} from "react-bootstrap"
import {MySelect} from "../../components/MyForms/MySelect"
import {multipleExist} from "../../helpers/multipleExist"
import {ArtaInfoMonitoring, ArtaInfoMonitoringCamera, ArtaInfoMonitoringDrive, ArtaInfoMonitoringRecord, HostType} from "../../Types/Types"
import {errorHandler, setMessage, setStatus} from "../../store/appReducer"
import {getHost} from "../../store/hostsReducer"
import {artaAPI} from "../../api/artaAPI"
import {deviceAPI} from "../../api/deviceAPI"

type PropsTypes = {
    id: string
    host: HostType
    controller: AbortController
    loading: boolean
    setLoading: (value: boolean) => void
    setShowModalArtaItems: React.Dispatch<React.SetStateAction<boolean>>
    dispatch: any
    artaServersOptions: Array<{ label: string, value: number }>
    setProgressTotal: React.Dispatch<React.SetStateAction<number>>
    setProgressCurrent: React.Dispatch<React.SetStateAction<number>>
}
export const AddArtaItems: React.FC<PropsTypes> = memo(({
                                                            id,
                                                            host,
                                                            setShowModalArtaItems,
                                                            dispatch,
                                                            loading,
                                                            setLoading,
                                                            controller,
                                                            artaServersOptions,
                                                            setProgressTotal,
                                                            setProgressCurrent,
                                                        }) =>
{
    const [infoMonitoring, setInfoMonitoring] = useState<ArtaInfoMonitoring | null>(null)
    const [selectedArtaServer, setSelectedArtaServer] = useState<number | null>(null)
    const [active, setActive] = useState(true)

    const [checkedRam, setCheckedRam] = useState<boolean>(false)
    const [checkedCpu, setCheckedCpu] = useState<boolean>(false)
    const [checkedCameras, setCheckedCameras] = useState<Array<number>>([])
    const [checkedRecords, setCheckedRecords] = useState<Array<number>>([])
    const [checkedDrives, setCheckedDrives] = useState<Array<string>>([])

    const allCameraIds = useMemo(() => infoMonitoring?.cameras.map(i => i.idCamera) || [], [infoMonitoring])
    const allRecordIds = useMemo(() => infoMonitoring?.records.map(i => i.idCamera) || [], [infoMonitoring])
    const allDriveIds = useMemo(() => infoMonitoring?.drives.map(i => i.name) || [], [infoMonitoring])

    useEffect(() =>
    {
        if (artaServersOptions && !selectedArtaServer)
        {
            setSelectedArtaServer(artaServersOptions[0]?.value)
        }
    }, [artaServersOptions, selectedArtaServer])

    useEffect(() =>
    {
        setInfoMonitoring(null)
    }, [selectedArtaServer])

    const onHideArtaItemModal = useCallback(() =>
    {
        setShowModalArtaItems(false)
        setInfoMonitoring(null)
    }, [setShowModalArtaItems])

    const checkedAll = useMemo(() => checkedRam && checkedCpu && multipleExist(checkedCameras, allCameraIds) &&
            multipleExist(checkedRecords, allRecordIds) && multipleExist(checkedDrives, allDriveIds),
        [allCameraIds, allDriveIds, allRecordIds, checkedCameras, checkedCpu, checkedDrives, checkedRam, checkedRecords])

    const checkAll = useCallback(() =>
    {
        if (infoMonitoring && checkedRam && checkedCpu && multipleExist(checkedCameras, allCameraIds) && multipleExist(checkedRecords, allRecordIds) &&
            multipleExist(checkedDrives, allDriveIds))
        {
            setCheckedRam(false)
            setCheckedCpu(false)
            setCheckedCameras([])
            setCheckedRecords([])
            setCheckedDrives([])
        }
        else
        {
            setCheckedRam(true)
            setCheckedCpu(true)
            setCheckedCameras(allCameraIds)
            setCheckedRecords(allRecordIds)
            setCheckedDrives(allDriveIds)
        }
    }, [infoMonitoring, checkedRam, checkedCpu, checkedCameras, allCameraIds, checkedRecords, allRecordIds, checkedDrives, allDriveIds])

    const checkAllCameras = useCallback(() =>
    {
        if (infoMonitoring && infoMonitoring.cameras.length > 0)
        {
            if (multipleExist(checkedCameras, allCameraIds))
            {
                setCheckedCameras([])
            }
            else
            {
                setCheckedCameras(allCameraIds)
            }
        }
    }, [infoMonitoring, checkedCameras, allCameraIds])

    const checkAllRecords = useCallback(() =>
    {
        if (infoMonitoring && infoMonitoring.records.length > 0)
        {
            if (multipleExist(checkedRecords, allRecordIds))
            {
                setCheckedRecords([])
            }
            else
            {
                setCheckedRecords(allRecordIds)
            }
        }
    }, [infoMonitoring, checkedRecords, allRecordIds])

    const checkAllDrives = useCallback(() =>
    {
        if (infoMonitoring && infoMonitoring.drives.length > 0)
        {
            if (multipleExist(checkedDrives, allDriveIds))
            {
                setCheckedDrives([])
            }
            else
            {
                setCheckedDrives(allDriveIds)
            }
        }
    }, [infoMonitoring, checkedDrives, allDriveIds])

    const handleCheckCamera = useCallback((value: number) =>
    {
        if (checkedCameras.includes(value)) setCheckedCameras(checkedCameras.filter(i => i !== value))
        else setCheckedCameras([...checkedCameras, value])
    }, [checkedCameras])

    const handleCheckRecord = useCallback((value: number) =>
    {
        if (checkedRecords.includes(value)) setCheckedRecords(checkedRecords.filter(i => i !== value))
        else setCheckedRecords([...checkedRecords, value])
    }, [checkedRecords])

    const handleCheckDrive = useCallback((value: string) =>
    {
        if (checkedDrives.includes(value)) setCheckedDrives(checkedDrives.filter(i => i !== value))
        else setCheckedDrives([...checkedDrives, value])
    }, [checkedDrives])

    const getInfoMonitoring = useCallback(async () =>
    {
        setCheckedRam(false)
        setCheckedCpu(false)
        setCheckedCameras([])
        setCheckedRecords([])
        setCheckedDrives([])
        setProgressTotal(0)
        setProgressCurrent(0)

        if (selectedArtaServer && host)
        {
            const server = host?.devices?.data?.find(i => i.arta?.idArtaServer === selectedArtaServer)

            if (server)
            {
                try
                {
                    setLoading(true)
                    const res = await artaAPI.getArtaInfoMonitoring({
                        idServer: selectedArtaServer,
                        controller,
                    })
                    let existRam = false
                    let existCpu = false
                    const existCameraIds: Array<number> = []
                    const existRecordIds: Array<number> = []
                    const existDriveIds: Array<string> = []
                    if (res.status === 1)
                    {
                        dispatch(setStatus("reject"))
                        dispatch(setMessage({type: "error", message: res.message}))
                        return
                    }
                    else
                    {
                        if (host?.devices?.data?.find(i => i.arta?.idMasterItem === server.id && i.arta?.artaTriggerType === "ram"))
                        {
                            existRam = true
                        }

                        if (host?.devices?.data?.find(i => i.arta?.idMasterItem === server.id && i.arta?.artaTriggerType === "temperature"))
                        {
                            existCpu = true
                        }

                        res?.data?.cameras.forEach((camera: ArtaInfoMonitoringCamera) =>
                        {
                            if (host?.devices?.data?.find(i => i.arta?.idMasterItem === server.id && i.arta?.artaTriggerType === "camera" &&
                                i.arta?.control === `${camera.port}`))
                            {
                                existCameraIds.push(camera.idCamera)
                            }
                        })

                        res?.data?.records.forEach((record: ArtaInfoMonitoringRecord) =>
                        {
                            if (host?.devices?.data?.find(i => i.arta?.idMasterItem === server.id && i.arta?.artaTriggerType === "record" &&
                                i.arta?.control === `${record.idCamera}`))
                            {
                                existRecordIds.push(record.idCamera)
                            }
                        })

                        res?.data?.drives.forEach((drive: ArtaInfoMonitoringDrive) =>
                        {
                            if (host?.devices?.data?.find(i => i.arta?.idMasterItem === server.id && i.arta?.artaTriggerType === "drive" &&
                                i.arta?.control === drive.name))
                            {
                                existDriveIds.push(drive.name)
                            }
                        })

                        setCheckedRam(existRam)
                        setCheckedCpu(existCpu)
                        setCheckedCameras(existCameraIds)
                        setCheckedRecords(existRecordIds)
                        setCheckedDrives(existDriveIds)

                        setInfoMonitoring(res.data)
                    }
                } catch (e: any)
                {
                    errorHandler(e, dispatch)
                } finally
                {
                    setLoading(false)
                }
            }
        }
    }, [selectedArtaServer, controller, dispatch, host, setLoading, setProgressCurrent, setProgressTotal])

    const submitArtaItems = useCallback(async () =>
    {
        const finallyMethod = () =>
        {
            dispatch(getHost({id, controller}))
            onHideArtaItemModal()
            setLoading(false)
            setCheckedCameras([])
            setCheckedRecords([])
            setCheckedDrives([])
            setProgressTotal(0)
            setProgressCurrent(0)
        }

        if (infoMonitoring && selectedArtaServer && host)
        {
            const server = host?.devices?.data?.find(i => i.arta?.idArtaServer === selectedArtaServer)

            if (server)
            {
                setLoading(true)

                const devicesToDelete: Array<string> = []

                host.devices.data
                    .filter(i => i.arta && i.arta.idMasterItem === server.id)
                    .forEach(i =>
                    {
                        if (i.arta)
                        {
                            if (i.arta?.idMasterItem === server.id && i.arta.artaTriggerType === "ram" && !checkedRam)
                            {
                                devicesToDelete.push(i.id)
                            }
                            else if (i.arta?.idMasterItem === server.id && i.arta.artaTriggerType === "temperature" && !checkedCpu)
                            {
                                devicesToDelete.push(i.id)
                            }
                            else if (i.arta.artaTriggerType === "camera")
                            {
                                const camera = infoMonitoring.cameras.find(f => i.arta?.idMasterItem === server.id &&
                                    i.arta?.artaTriggerType === "camera" && `${f.port}` === i.arta?.control)

                                if (!camera || !checkedCameras.includes(camera.idCamera))
                                {
                                    devicesToDelete.push(i.id)
                                }
                            }
                            else if (i.arta.artaTriggerType === "record")
                            {
                                const record = infoMonitoring.records.find(f => i.arta?.idMasterItem === server.id &&
                                    i.arta?.artaTriggerType === "record" && `${f.idCamera}` === i.arta?.control)

                                if (!record || !checkedRecords.includes(record.idCamera))
                                {
                                    devicesToDelete.push(i.id)
                                }
                            }
                            else if (i.arta.artaTriggerType === "drive")
                            {
                                const drive = infoMonitoring.drives.find(f => i.arta?.idMasterItem === server.id &&
                                    i.arta?.artaTriggerType === "drive" && f.name === i.arta?.control)

                                if (!drive || !checkedDrives.includes(drive.name))
                                {
                                    devicesToDelete.push(i.id)
                                }
                            }
                        }
                    })

                setProgressTotal(2 + checkedCameras.length + checkedRecords.length + checkedDrives.length + devicesToDelete.length)
                setShowModalArtaItems(false)

                if (checkedRam)
                {
                    try
                    {
                        const res = await artaAPI.editArtaDevice({
                            id: host.devices.data.find(f => f.arta?.idMasterItem === server.id && f.arta?.artaTriggerType === "ram")?.id ?? "0",
                            name: `${server.name} (Контроль RAM)`,
                            idHost: host.id,
                            control: "500MB",
                            idMasterItem: server.id,
                            active: active,
                            priority: "3",
                            artaTriggerType: "ram",
                            controller,
                        })
                        if (res?.status === 1)
                        {
                            dispatch(setMessage({type: "error", message: res.message}))
                        }
                        setProgressCurrent(prev => prev + 1)
                    } catch (e: any)
                    {
                        errorHandler(e, dispatch)
                    }
                }

                if (checkedCpu)
                {
                    try
                    {
                        const res = await artaAPI.editArtaDevice({
                            id: host.devices.data.find(f => f.arta?.idMasterItem === server.id && f.arta?.artaTriggerType === "temperature")?.id ?? "0",
                            name: `${server.name} (Контроль CPU)`,
                            idHost: host.id,
                            control: "100C",
                            idMasterItem: server.id,
                            active: active,
                            priority: "3",
                            artaTriggerType: "temperature",
                            controller,
                        })
                        if (res?.status === 1)
                        {
                            dispatch(setMessage({type: "error", message: res.message}))
                        }
                        setProgressCurrent(prev => prev + 1)
                    } catch (e: any)
                    {
                        errorHandler(e, dispatch)
                    }
                }

                for await (const i of infoMonitoring.cameras)
                {
                    if (checkedCameras.includes(i.idCamera))
                    {
                        try
                        {
                            const res = await artaAPI.editArtaDevice({
                                id: host.devices.data.find(f => f.arta?.idMasterItem === server.id && f.arta?.artaTriggerType === "camera" &&
                                    f.arta.control === `${i.port}`)?.id ?? "0",
                                name: `${server.name} - ${i.nameCamera} (Контроль сети)`,
                                idHost: host.id,
                                control: `${i.port}`,
                                idMasterItem: server.id,
                                active: active,
                                priority: "3",
                                artaTriggerType: "camera",
                                controller,
                            })
                            if (res?.status === 1)
                            {
                                dispatch(setMessage({type: "error", message: res.message}))
                            }
                            setProgressCurrent(prev => prev + 1)
                        } catch (e: any)
                        {
                            errorHandler(e, dispatch)
                        }
                    }
                }

                for await (const i of infoMonitoring.records)
                {
                    if (checkedRecords.includes(i.idCamera))
                    {
                        try
                        {
                            const res = await artaAPI.editArtaDevice({
                                id: host.devices.data.find(f => f.arta?.idMasterItem === server.id && f.arta?.artaTriggerType === "record" &&
                                    f.arta.control === `${i.idCamera}`)?.id ?? "0",
                                name: `${server.name} - ${i.nameCamera} (Контроль записи)`,
                                idHost: host.id,
                                control: `${i.idCamera}`,
                                idMasterItem: server.id,
                                active: active,
                                priority: "3",
                                artaTriggerType: "record",
                                controller,
                            })
                            if (res?.status === 1)
                            {
                                dispatch(setMessage({type: "error", message: res.message}))
                            }
                            setProgressCurrent(prev => prev + 1)
                        } catch (e: any)
                        {
                            errorHandler(e, dispatch)
                        }
                    }
                }

                for await (const i of infoMonitoring.drives)
                {
                    if (checkedDrives.includes(i.name))
                    {
                        try
                        {
                            const res = await artaAPI.editArtaDevice({
                                id: host.devices.data.find(f => f.arta?.idMasterItem === server.id && f.arta?.artaTriggerType === "drive" &&
                                    f.arta.control === i.name)?.id ?? "0",
                                name: `${server.name} - ${i.name} (Контроль диска)`,
                                idHost: host.id,
                                control: i.name,
                                idMasterItem: server.id,
                                active: active,
                                priority: "3",
                                artaTriggerType: "drive",
                                controller,
                            })
                            if (res?.status === 1)
                            {
                                dispatch(setMessage({type: "error", message: res.message}))
                            }
                            setProgressCurrent(prev => prev + 1)
                        } catch (e: any)
                        {
                            errorHandler(e, dispatch)
                        }
                    }
                }

                if (devicesToDelete.length > 0) await deviceAPI.deleteDevice({id: devicesToDelete})

                dispatch(setMessage({type: "success", message: `Готово`}))

                finallyMethod()
            }
        }
    }, [infoMonitoring, selectedArtaServer, host, dispatch, id, controller, onHideArtaItemModal, setLoading, setProgressTotal, setProgressCurrent,
        checkedCameras, checkedRecords, checkedDrives, setShowModalArtaItems, checkedRam, checkedCpu, active])

    const infoMonitoringMap = useMemo(() =>
    {
        if (infoMonitoring)
        {
            const cameras = infoMonitoring.cameras.map(i =>
            {
                return (
                    <label key={i.idCamera}
                           className="m-1 align-self-start"
                           style={{paddingLeft: 15}}>
                        <Form.Check type="checkbox"
                                    id={`checkedCameras-${i.idCamera}`}
                                    checked={checkedCameras.includes(i.idCamera)}
                                    onChange={() => handleCheckCamera(i.idCamera)}
                                    label={i.nameCamera}
                        />
                    </label>
                )
            })

            const records = infoMonitoring.records.map(i =>
            {
                return (
                    <label key={i.idCamera}
                           className="m-1 align-self-start"
                           style={{paddingLeft: 15}}>
                        <Form.Check type="checkbox"
                                    id={`checkedRecords-${i.idCamera}`}
                                    checked={checkedRecords.includes(i.idCamera)}
                                    onChange={() => handleCheckRecord(i.idCamera)}
                                    label={i.nameCamera}
                        />
                    </label>
                )
            })

            const drives = infoMonitoring.drives.map(i =>
            {
                return (
                    <label key={i.name}
                           className="m-1 align-self-start"
                           style={{paddingLeft: 15}}>
                        <Form.Check type="checkbox"
                                    id={`checkedRecords-${i.name}`}
                                    checked={checkedDrives.includes(i.name)}
                                    onChange={() => handleCheckDrive(i.name)}
                                    label={i.name}
                        />
                    </label>
                )
            })

            return (
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 5}}>
                    <hr style={{alignSelf: "stretch"}}/>

                    <label className="m-1 align-self-start">
                        <Form.Check type="checkbox"
                                    id="checkedAll"
                                    onChange={checkAll}
                                    checked={checkedAll}
                                    label="Все"
                        />
                    </label>

                    <label className="m-1 align-self-start"
                           style={{paddingLeft: 15}}>
                        <Form.Check type="checkbox"
                                    id="checkedRam"
                                    checked={checkedRam}
                                    onChange={() => setCheckedRam(prev => !prev)}
                                    label="Контроль оперативной памяти"
                        />
                    </label>

                    <label className="m-1 align-self-start"
                           style={{paddingLeft: 15}}>
                        <Form.Check type="checkbox"
                                    id="checkedCpu"
                                    checked={checkedCpu}
                                    onChange={() => setCheckedCpu(prev => !prev)}
                                    label="Контроль температуры CPU"
                        />
                    </label>

                    <div className="m-1 align-self-start">Контроль доступности камер</div>

                    <label className="m-1 align-self-start"
                           style={{paddingLeft: 15}}>
                        <Form.Check type="checkbox"
                                    id="checkAllCameras"
                                    onChange={checkAllCameras}
                                    checked={checkedCameras.length > 0 && multipleExist(checkedCameras, allCameraIds)}
                                    label="Все"
                        />
                    </label>

                    {cameras}

                    <div className="m-1 align-self-start">Контроль записи</div>

                    <label className="m-1 align-self-start"
                           style={{paddingLeft: 15}}>
                        <Form.Check type="checkbox"
                                    id="checkAllRecords"
                                    onChange={checkAllRecords}
                                    checked={checkedRecords.length > 0 && multipleExist(checkedRecords, allRecordIds)}
                                    label="Все"
                        />
                    </label>

                    {records}

                    <div className="m-1 align-self-start">Контроль дисков</div>

                    <label className="m-1 align-self-start"
                           style={{paddingLeft: 15}}>
                        <Form.Check type="checkbox"
                                    id="checkAllDrives"
                                    onChange={checkAllDrives}
                                    checked={checkedDrives.length > 0 && multipleExist(checkedDrives, allDriveIds)}
                                    label="Все"
                        />
                    </label>

                    {drives}
                </div>
            )
        }
    }, [infoMonitoring, checkAll, checkedAll, checkedRam, checkedCpu, checkAllCameras, checkedCameras, allCameraIds, checkAllRecords, checkedRecords,
        allRecordIds, checkAllDrives, checkedDrives, allDriveIds, handleCheckCamera, handleCheckRecord, handleCheckDrive])

    return (
        <>
            <Modal show
                   onHide={onHideArtaItemModal}
            >
                <Modal.Body style={{textAlign: "center"}}>
                    <div style={{width: 250, margin: "0 auto"}}>
                        <MySelect value={selectedArtaServer}
                                  label="АРТА Сервер"
                                  options={artaServersOptions}
                                  onChange={(item) => setSelectedArtaServer(item as number | null)}
                        />
                    </div>
                    <Button variant="outline-secondary"
                            onClick={onHideArtaItemModal}>
                        Отмена
                    </Button>
                    <Button variant="primary"
                            type="submit"
                            disabled={loading || !selectedArtaServer}
                            style={{marginLeft: 15}}
                            onClick={getInfoMonitoring}
                    >
                        {loading ? "Загрузка..." : "Запросить"}
                    </Button>
                    {
                        infoMonitoringMap
                            ? <div>
                                {infoMonitoringMap}
                                <div className="checkbox m-1">
                                    <label>
                                        <Form.Check type="checkbox"
                                                    id={"active"}
                                                    checked={active}
                                                    onChange={() => setActive(prev => !prev)}
                                                    label="Активировано"
                                        />
                                    </label>
                                </div>
                            </div>
                            : null
                    }
                </Modal.Body>
                {
                    infoMonitoringMap
                        ? <Modal.Footer>
                            <Button variant="outline-secondary"
                                    onClick={onHideArtaItemModal}>
                                Отмена
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    onClick={submitArtaItems}
                                    disabled={loading}
                            >
                                {loading ? "Загрузка..." : "Обновить"}
                            </Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>
        </>
    )
})